import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import { Column } from 'components/atoms';

const AccordionContent = props => {
  const { children, className } = props;

  return <Column className={className}>{children}</Column>;
};

AccordionContent.defaultProps = {
  className: '',
};

AccordionContent.propTypes = {
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default AccordionContent;
