import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Row, Column, Text } from 'components/atoms';

const AccordionPanel = ({ data, className }) => {
  return (
    <Column className={cn('accordion-block__header', [className])}>
      <Row>
        {data.map((item, id) => {
          const { title, col, columnClassName, textClassName } = item;
          return (
            <Column
              className={cn('accordion-block__header-column', [
                columnClassName,
              ])}
              col={col}
              key={id}
            >
              <Text className={cn([textClassName])}>{title}</Text>
            </Column>
          );
        })}
      </Row>
    </Column>
  );
};

AccordionPanel.defaultProps = {
  className: '',
};

AccordionPanel.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default AccordionPanel;
