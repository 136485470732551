import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Button,
  Text,
  Link,
} from 'components/atoms';
import AccordionPanel from 'components/molecules/AccordionPanel';
import AccordionContent from 'components/molecules/AccordionContent';

const findYear = (startYear, endYear, isGoing) => {
  const years = [startYear, endYear];
  const yearsIsGoind = [startYear, 'Present'];
  const singleYear = years.filter(year => {
    return year !== '';
  });

  let year;
  if (isGoing) {
    year = yearsIsGoind.join(' – ');
  } else if (startYear === endYear) {
    year = startYear;
  } else {
    year = singleYear.join(' – ');
  }

  return year;
};

const sortingWorks = data => {
  const sortAlphabeticalWorks = data.sort(
    ({ column_1: { name: nameFirst } }, { column_1: { name: nameSecond } }) => {
      return nameFirst.trim() > nameSecond.trim() ? 1 : -1;
    }
  );

  const sortYearWorks = sortAlphabeticalWorks.sort(
    (
      { column_3: { year_from: startYearFirst } },
      { column_3: { year_from: startYearSecond } }
    ) => {
      return startYearSecond - startYearFirst;
    }
  );

  return sortYearWorks;
};

const ProjectsAccordion = ({ data, tagsData, className }) => {
  const { works } = data;

  const LIMIT_VALUE = 27;

  const [itemsLimit, setItemsLimit] = useState(LIMIT_VALUE);
  const [isHideBtnVisible, hideBtnVisible] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const showItemDescription = useCallback(
    id => {
      setActiveItem(id);
    },
    [setActiveItem]
  );

  const itemsLength = works.length;
  const hiddenItems = itemsLength - itemsLimit;

  const onLoadMore = () => {
    setItemsLimit(itemsLimit + hiddenItems);
    hideBtnVisible(true);
  };

  const accordionPanelData = [
    {
      title: 'Project',
      col: '5',
      columnClassName: '',
      textClassName: 'body-sm_bold',
    },
    {
      title: 'Deliverables',
      col: '3',
      columnClassName: '',
      textClassName: 'body-sm_bold',
    },
    {
      title: 'Year',
      col: '4',
      columnClassName: '',
      textClassName: 'body-sm_bold',
    },
  ];

  const sortWorks = sortingWorks(works);

  return (
    <Section className={cn('accordion-block', [className])}>
      <Container>
        <Row>
          <AccordionPanel data={accordionPanelData} />

          {sortWorks.slice(0, itemsLimit).map((work, workId) => {
            const { column_1, column_2, column_3 } = work;
            const { name, description } = column_1;
            const { deliverables, expertise, industry, country } = column_2;
            const { name: deliverables_name } = deliverables || {};
            const { name: country_name } = country || {};
            const {
              ongoing_activity,
              year_from,
              year_to,
              references,
            } = column_3;

            const year = findYear(year_from, year_to, ongoing_activity);

            const sortExpertiseAlphabetical = expertise.sort(
              ({ name: a }, { name: b }) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
              }
            );

            const tagsInfo = tagsData.map(edges => edges.node);

            const expertises =
              expertise &&
              sortExpertiseAlphabetical.map(({ term_id }, expertiseId) => {
                const tag = tagsInfo.find(
                  ({ wordpress_id }) => wordpress_id === term_id
                );

                const { path, name } = tag;

                const isLink = path === null;

                const textOrLink = isLink ? (
                  <Text
                    tag="span"
                    className="body-sm body-sm_bold"
                    key={expertiseId}
                  >
                    {name}
                  </Text>
                ) : (
                  <div
                    className="accordion-block__item__meta__additional__tags__link"
                    key={expertiseId}
                  >
                    <Link
                      className="accordion-block__item__meta__additional__tags__link-item"
                      href={path}
                    >
                      <Text tag="span" className="body-sm body-sm_bold">
                        {name}
                      </Text>
                    </Link>
                  </div>
                );

                return textOrLink;
              });

            return (
              <AccordionContent
                className={cn('accordion-block__container-item', {
                  'accordion-block__container-item_active':
                    workId === activeItem,
                })}
                key={workId}
              >
                <div
                  onClick={() => {
                    showItemDescription(workId);
                  }}
                  className="accordion-block__item"
                  role="button"
                  tabIndex={workId}
                  onKeyDown={() => showItemDescription(workId)}
                >
                  <Row className="accordion-block__item__header">
                    <Column
                      className="accordion-block__item-column"
                      col="12"
                      lg="5"
                    >
                      {name && <Text>{name}</Text>}
                    </Column>
                    <Column
                      className="accordion-block__item-column d-none d-lg-block"
                      col="3"
                    >
                      {deliverables_name && <Text>{deliverables_name}</Text>}
                    </Column>
                    <Column
                      className="accordion-block__item-column d-none d-lg-block"
                      col="4"
                    >
                      {year && <Text>{year}</Text>}
                    </Column>
                  </Row>

                  <Row className="accordion-block__item__meta">
                    <Column
                      className="accordion-block__item-column"
                      col="12"
                      lg="5"
                    >
                      <Text>{description}</Text>
                    </Column>

                    <Column
                      className="accordion-block__item__meta__mobile d-lg-none"
                      col="12"
                    >
                      <div className="accordion-block__item-column">
                        {deliverables_name && (
                          <Text className="headline-4">
                            {deliverables_name}
                          </Text>
                        )}
                      </div>
                      <div className="accordion-block__item-column">
                        {year && <Text className="headline-4">{year}</Text>}
                      </div>
                    </Column>

                    <Column
                      className="accordion-block__item-column"
                      col="12"
                      lg="3"
                    >
                      {expertise && (
                        <div className="accordion-block__item__meta__additional">
                          <div className="accordion-block__item__meta__additional__title">
                            <Text>Expertise:</Text>
                          </div>
                          <div className="accordion-block__item__meta__additional__tags">
                            {expertises}
                          </div>
                        </div>
                      )}

                      {industry && (
                        <div className="accordion-block__item__meta__additional">
                          <div className="accordion-block__item__meta__additional__title">
                            <Text>Industry:</Text>
                          </div>
                          <div className="accordion-block__item__meta__additional__tags">
                            {industry.map(({ name }, industryId) => (
                              <Text
                                tag="span"
                                className="body-sm body-sm_bold"
                                key={industryId}
                              >
                                {`${name}${
                                  industryId !== industry.length - 1
                                    ? ' / '
                                    : ''
                                }`}
                              </Text>
                            ))}
                          </div>
                        </div>
                      )}

                      {country && (
                        <div className="accordion-block__item__meta__additional">
                          <div className="accordion-block__item__meta__additional__title">
                            <Text>Country:</Text>
                          </div>
                          <div className="accordion-block__item__meta__additional__tags">
                            <Text tag="span" className="body-sm body-sm_bold">
                              {country_name}
                            </Text>
                          </div>
                        </div>
                      )}
                    </Column>

                    {references && (
                      <Column
                        className="accordion-block__item-column"
                        col="12"
                        lg="4"
                      >
                        <div className="accordion-block__item__meta__link">
                          {references.map(({ link, text }, referenceId) => (
                            <Link
                              href={link}
                              isRouter={false}
                              key={referenceId}
                              target="_blank"
                            >
                              <Text className="body-sm body-sm_bold">
                                {text}
                              </Text>
                            </Link>
                          ))}
                        </div>
                      </Column>
                    )}
                  </Row>
                </div>
              </AccordionContent>
            );
          })}

          {!isHideBtnVisible && (
            <Column className="accordion-block__footer">
              <Button onClick={onLoadMore} title={`Load ${hiddenItems} more`} />
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

ProjectsAccordion.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProjectsAccordion;
